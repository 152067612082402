<template>
  <div
    class="auth-loading parent-loading"
    :class="`parent-loading-${className}`"
  >
    <div className="text-center">
      <div className="loading"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: 'auth',
    },
  },
};
</script>
